import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import bs58 from 'bs58';
import { useAppContext } from '../AppContext';
import './RegistrationPage.css';


const RegistrationPage = () => {
  const navigate = useNavigate();
  const { connection } = useConnection();
  const { publicKey, signMessage, sendTransaction } = useWallet();
  const [status, setStatus] = useState('');
  const { reservedDomain, formData, updateFormData } = useAppContext();
  const [errors, setErrors] = useState({});
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const backgroundInputRef = useRef(null);
  const logoInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState('basic');
  const [showPopUp, setShowPopUp] = useState(false);
  const [memeFiles, setMemeFiles] = useState([null, null, null]);
  const memeInputRefs = [useRef(null), useRef(null), useRef(null)];
  const [backgroundPreview, setBackgroundPreview] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [memeFilePreviews, setMemeFilePreviews] = useState([null, null, null]);
  const [overlayFile, setOverlayFile] = useState(null);
  const [defaultBgFile, setDefaultBgFile] = useState(null);
  const [overlayPreview, setOverlayPreview] = useState(null);
  const [defaultBgPreview, setDefaultBgPreview] = useState(null);
  const overlayInputRef = useRef(null);
  const defaultBgInputRef = useRef(null);
  const [isPopUpVisible, setIsPopUpVisible] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [enableMemeMaker, setEnableMemeMaker] = useState(false);

  const closePopUp = () => {
    setIsPopUpVisible(false);
  };
  useEffect(() => {
    if (!formData.template_id) { updateFormData('template_id', '4chan'); }
    if (formData.pump_fun === undefined) updateFormData('pump_fun', false);
    if (formData.dexscreener === undefined) updateFormData('dexscreener', false);
    if (formData.raydium === undefined) updateFormData('raydium', false);
  }, [formData, updateFormData]);
  useEffect(() => {
    setShowPopUp(true);
    const timer = setTimeout(() => {
      setShowPopUp(false);
    }, 12000); // Hide the message after 12 seconds
  
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const isValid = formData.name && 
                    formData.ticker && 
                    formData.contract_address &&
                    Object.values(errors).every(error => error === '');
    setFormIsValid(isValid);
  }, [formData, errors]);
  
  const handleMemeFileChange = (index, file) => {
    console.log(`Meme file change for index ${index}:`, file);
    const newMemeFiles = [...memeFiles];
    newMemeFiles[index] = file;
    setMemeFiles(newMemeFiles);

    const newMemeFilePreviews = [...memeFilePreviews];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        newMemeFilePreviews[index] = reader.result;
        setMemeFilePreviews(newMemeFilePreviews);
      };
      reader.readAsDataURL(file);
    } else {
      newMemeFilePreviews[index] = null;
      setMemeFilePreviews(newMemeFilePreviews);
    }
  };

  const uploadFile = async (file, fileType) => {
    if (!reservedDomain || !reservedDomain.subdomain) {
      throw new Error('No subdomain found');
    }
  
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        const base64File = reader.result;
  
        try {
          const response = await fetch('https://api.site.fun/api/upload-image', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              base64_file: base64File,
              subdomain: reservedDomain.subdomain, 
              file_type: fileType,
            }),
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const jsonResponse = await response.json();
          resolve(jsonResponse.url);
        } catch (error) {
          reject(error);
        }
      };
    });
  };


  const handleFileChange = (e, setFile, setPreview) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? checked : value;
  
    // Apply real-time input validation and formatting
    switch (name) {
      case 'name':
        newValue = newValue.slice(0, 32); // Limit to 32 characters
        break;
      case 'ticker':
        newValue = newValue.replace(/[^a-zA-Z0-9]/g, '').slice(0, 10);
        break;
      case 'description':
        newValue = newValue.slice(0, 300); // Limit to 300 characters
        break;
      case 'twitter_link':
      case 'telegram_link':
        newValue = newValue.replace(/\s/g, '').slice(0, 100); // Remove spaces and limit to 100 characters
        break;
      case 'contract_address':
        newValue = newValue.replace(/[^a-zA-Z0-9]/g, '').slice(0, 100);
        break;
      default:
        break;
    }
    updateFormData(name, newValue);
  const error = validateField(name, newValue);
  setErrors(prev => ({ ...prev, [name]: error }));
  if (name === 'enable_meme_maker') {
    setEnableMemeMaker(checked);
    if (!checked) {
      // Clear meme maker images when disabling
      clearMemeMakerImages();
    }
  }
};

const clearMemeMakerImages = () => {
  setOverlayFile(null);
  setDefaultBgFile(null);
  setOverlayPreview(null);
  setDefaultBgPreview(null);
  if (overlayInputRef.current) overlayInputRef.current.value = '';
  if (defaultBgInputRef.current) defaultBgInputRef.current.value = '';
};

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'name':
        if (!value.trim()) {
          error = 'Name is required';
        } else if (value.length > 32) {
          error = 'Name must be 32 characters or less';
        }
        break;
      case 'ticker':
        if (!value.trim()) {
          error = 'Ticker is required';
        } else if (value.length > 10) {
          error = 'Ticker must be 10 characters or less';
        } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
          error = 'Ticker must be alphanumeric';
        }
        break;
      case 'description':
        if (value.length > 300) {
          error = 'Description must be 300 characters or less';
        }
        break;
        case 'twitter_link':
          if (value) {
            const lowercaseValue = value.toLowerCase();
            if (!lowercaseValue.includes('x.com') && !lowercaseValue.includes('twitter.com')) {
              error = 'Twitter link is not valid';
            }
          }
          break;
          case 'telegram_link':
            if (value) {
              const lowercaseValue = value.toLowerCase();
              if (!lowercaseValue.includes('t.me') && !lowercaseValue.includes('telegram.org')) {
                error = 'telegram link is not valid';
              }
            }
            break;
      case 'contract_address':
        if (!value.trim()) {
          error = 'Contract address is required';
        } else if (value.length < 25 || value.length > 100) {
          error = 'Contract address must be between 25 and 100 characters';
        } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
          error = 'Contract address must be alphanumeric';
        }
        break;
      default:
        break;
    }
    setErrors(prev => ({ ...prev, [name]: error }));
    return error;
  };
  

  const validateForm = () => {
    let newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      newErrors[key] = validateField(key, value);
    });
    setErrors(newErrors);
    return Object.values(newErrors).every(error => error === '');
  };

  const handleVerifyAndPay = useCallback(async () => {
    if (!formIsValid) {
      setStatus('Please correct the form errors');
      return;
    }

    if (!publicKey) {
      setStatus('Wallet not connected');
      return;
    }

    if (!reservedDomain || !reservedDomain.id) {
      setStatus('Error: No reservation found. Please go back and reserve a domain.');
      return;
    }
  
    setStatus('Verifying wallet...');
  
    try {
      // Wallet verification
      const message = `Verify wallet ownership for site.fun: ${new Date().toISOString()}`;
      const encodedMessage = new TextEncoder().encode(message);
      const signedMessage = await signMessage(encodedMessage);
      const signature = bs58.encode(signedMessage);
  
      // Send verification data to backend
      const verifyResponse = await fetch('https://api.site.fun/api/verify_signature', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          public_key: publicKey.toBase58(),
          message: bs58.encode(encodedMessage),
          signature: signature,
        }),
      });
      
      const verifyResult = await verifyResponse.json();
      if (!verifyResult.is_valid) {
        setStatus('Signature verification failed');
        return;
      }

      setStatus('Wallet verified. Processing payment...');

      // Payment transaction
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey('31cqjMkqJno1asiaWdotSbvzY9ztpxocRgEox6Nniioa'),
          lamports: 1 * LAMPORTS_PER_SOL,
        })
      );
  
      const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.lastValidBlockHeight = lastValidBlockHeight;
  
      const txSignature = await sendTransaction(transaction, connection);
      setStatus('Confirming transaction...');
      const confirmation = await connection.confirmTransaction({
        signature: txSignature,
        blockhash: blockhash,
        lastValidBlockHeight: lastValidBlockHeight,
      });
      if (confirmation.value.err) {
        setStatus('Payment Failed');
        return;
      }
      
      setStatus('Payment Successful. Uploading files...');
      let backgroundUrl, logoUrl, meme1Url, meme2Url, meme3Url, overlayUrl, defaultBgUrl;
      if (backgroundFile) {
        backgroundUrl = await uploadFile(backgroundFile, 'background');
      }
      if (logoFile) {
        logoUrl = await uploadFile(logoFile, 'logo');
      }
      if (memeFiles[0]) {
        meme1Url = await uploadFile(memeFiles[0], 'meme1');
      }
      if (memeFiles[1]) {
        meme2Url = await uploadFile(memeFiles[1], 'meme2');
      }
      if (memeFiles[2]) {
        meme3Url = await uploadFile(memeFiles[2], 'meme3');
      }
      if (overlayFile) {
        overlayUrl = await uploadFile(overlayFile, 'overlay');
      }
      if (defaultBgFile) {
        defaultBgUrl = await uploadFile(defaultBgFile, 'defaultbgmememaker');
      }

      // BACKEND CALL
      const updateResponse = await fetch('https://api.site.fun/api/update-subdomain', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reservation_id: reservedDomain.id,
          wallet_address: publicKey.toString(),
          transaction_hash: txSignature,
          ...formData,
          background_image_url: backgroundUrl || '',
          logo_url: logoUrl || '',
          meme1: meme1Url || '',
          meme2: meme2Url || '',
          meme3: meme3Url || '',
          overlay: overlayUrl || '',
          defaultbgmememaker: defaultBgUrl || '',
          template_id: formData.template_id || '4chan',
        }),
      });

      const updateResult = await updateResponse.json();
      
      if (updateResult.success) {
        setStatus('Subdomain updated successfully');
        navigate('/success');
      } else {
        setStatus(`Failed to update subdomain: ${updateResult.error}`);
      }
      
    } catch (error) {
      console.error('Error:', error);
      setStatus(`Verification, Payment, Upload, or Update Failed: ${error.message}`);
    }
  }, [publicKey, signMessage, sendTransaction, connection, formData, navigate, reservedDomain, errors, backgroundFile, logoFile, memeFiles,overlayFile, defaultBgFile,formIsValid]);


  const isContractAddressValid = formData.contract_address && 
                               formData.contract_address.length >= 25 && 
                               formData.contract_address.length <= 100 && 
                               !/[^a-zA-Z0-9]/.test(formData.contract_address);

  if (!reservedDomain || !reservedDomain.subdomain) {
    return (
      <div className="registration-page">
        <h2>error</h2>
        <p>please go back and choose a name first.</p>
        <button onClick={() => navigate('/')}>[back to home]</button>
      </div>
    );
  }
  
  return (
    <div className="registration-page">
      <div className="registration-container">
        <h2>{reservedDomain.subdomain}.site.fun
        </h2>
        
        <div className="tab-container">
          <div className={`tab ${activeTab === 'basic' ? 'active' : ''}`} onClick={() => setActiveTab('basic')}>[basic]</div>
          <div className={`tab ${activeTab === 'social' ? 'active' : ''}`} onClick={() => setActiveTab('social')}>[socials]</div>
          <div className={`tab ${activeTab === 'technical' ? 'active' : ''}`} onClick={() => setActiveTab('technical')}>[technical]</div>
          <div className={`tab ${activeTab === 'design' ? 'active' : ''}`} onClick={() => setActiveTab('design')}>[design]</div>
          <div className={`tab ${activeTab === 'memes' ? 'active' : ''}`} onClick={() => setActiveTab('memes')}>[memes]</div>
          <div className={`tab ${activeTab === 'mememaker' ? 'active' : ''}`} onClick={() => setActiveTab('mememaker')}>[mememaker]</div>
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          <div className={`form-section ${activeTab === 'basic' ? 'active' : ''}`}>
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name (required, permanent)"
                required
                maxLength={32}
              />
              {errors.name && <span className="error">{errors.name}</span>}
              <span className="warning">You will NOT be able to change your name later.</span>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="ticker"
                value={formData.ticker}
                onChange={handleChange}
                placeholder="Ticker (required, permanent)"
                maxLength={10}
              />
              {errors.ticker && <span className="error">{errors.ticker}</span>}
              <span className="warning">You will NOT be able to change your ticker later.</span>
            </div>
            <div className="form-group">
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Description"
                maxLength={300}
              />
              {errors.description && <span className="error">{errors.description}</span>}
            </div>
          </div>

          <div className={`form-section ${activeTab === 'social' ? 'active' : ''}`}>
          <p>Enter your socials </p>
            <div className="form-group">
              <input
                type="text"
                name="twitter_link"
                value={formData.twitter_link}
                onChange={handleChange}
                placeholder="Twitter Link"
              />
              {errors.twitter_link && <span className="error">{errors.twitter_link}</span>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="telegram_link"
                value={formData.telegram_link}
                onChange={handleChange}
                placeholder="Telegram Link"
              />
              {errors.telegram_link && <span className="error">{errors.telegram_link}</span>}
            </div>
          </div>

          <div className={`form-section ${activeTab === 'technical' ? 'active' : ''}`}>
          <p>Enter your CA and choose the platforms you're present on</p>
          <span className="warning">If you launched on Moonshot, you can tick Dexscreener</span>
  <div className="form-group">
    <input
      type="text"
      name="contract_address"
      value={formData.contract_address}
      onChange={handleChange}
      placeholder="Contract Address (required, permanent)"
    />
    {errors.contract_address && <span className="error">{errors.contract_address}</span>}
    <span className="warning">You will NOT be able to change your contract address later.</span>
  </div>
  <div className="form-group">
    <label className={!isContractAddressValid ? 'disabled' : ''}>
      <input
        type="checkbox"
        name="pump_fun"
        checked={formData.pump_fun}
        onChange={handleChange}
        disabled={!isContractAddressValid}
      />
      You're present on Pump.fun
    </label>
  </div>
  <div className="form-group">
    <label className={!isContractAddressValid ? 'disabled' : ''}>
      <input
        type="checkbox"
        name="dexscreener"
        checked={formData.dexscreener}
        onChange={handleChange}
        disabled={!isContractAddressValid}
      />
      You're present on Dexscreener
    </label>
  </div>
  <div className="form-group">
    <label className={!isContractAddressValid ? 'disabled' : ''}>
      <input
        type="checkbox"
        name="raydium"
        checked={formData.raydium}
        onChange={handleChange}
        disabled={!isContractAddressValid}
      />
      You're tradable on Raydium
    </label>
  </div>
</div>
<div className={`form-section ${activeTab === 'design' ? 'active' : ''}`}>
<p>Choose your website's background, logo and template</p>
        <div className="image-upload-container">
          {[
            { type: 'background', file: backgroundFile, preview: backgroundPreview, setter: setBackgroundFile, previewSetter: setBackgroundPreview },
            { type: 'logo', file: logoFile, preview: logoPreview, setter: setLogoFile, previewSetter: setLogoPreview }
          ].map(({ type, file, preview, setter, previewSetter }) => (
            <div key={type} className="image-upload-square">
              <label htmlFor={`${type}-upload`} className="file-upload-label">
                {preview ? (
                  <img src={preview} alt={`${type} preview`} className="image-preview" />
                ) : (
                  `Upload ${type.charAt(0).toUpperCase() + type.slice(1)}`
                )}
              </label>
              <input
                type="file"
                id={`${type}-upload`}
                onChange={(e) => handleFileChange(e, setter, previewSetter)}
                ref={type === 'background' ? backgroundInputRef : logoInputRef}
                accept="image/*"
              />
            </div>
          ))}
        </div>
  <div className="form-group">
    <label htmlFor="template_id">Template:</label>
    <select
      id="template_id"
      name="template_id"
      value={formData.template_id}
      onChange={handleChange}
    >
       <option value="4chan">4chan</option>
       <option value="apesinvasion">apesinvasion</option>
      <option value="basic">basic</option>
      <option value="basicdark">basicdark</option>
      <option value="coinhub">coinhub</option>
      <option value="dark">dark</option>
      <option value="futuristic">futuristic</option>
      <option value="japan">japan</option>
      <option value="degen">degen</option>
      <option value="blackandwhite">blackandwhite</option>
      <option value="reddit">reddit</option>
      <option value="retro">retro</option>
      <option value="simple">simple</option>
    </select>
    <span className="warning2">Preview available templates on <a href="https://preview.site.fun" target="_blank">preview.site.fun</a></span>

  </div>
</div>

<div className={`form-section ${activeTab === 'memes' ? 'active' : ''}`}>
<p>Choose up to 3 memes to display on your website</p>
        <div className="meme-upload-container">
          {[0, 1, 2].map((index) => (
            <div key={index} className="meme-upload-square">
              <label htmlFor={`meme-upload-${index}`} className="file-upload-label">
                {memeFilePreviews[index] ? (
                  <img src={memeFilePreviews[index]} alt={`Meme ${index + 1} preview`} className="image-preview" />
                ) : (
                  'Upload Meme'
                )}
              </label>
              <input
                type="file"
                id={`meme-upload-${index}`}
                onChange={(e) => handleMemeFileChange(index, e.target.files[0])}
                ref={memeInputRefs[index]}
                accept="image/*"
              />
            </div>
          ))}
        </div>
      </div>
      <div className={`form-section ${activeTab === 'mememaker' ? 'active' : ''}`}>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              name="enable_meme_maker"
              checked={enableMemeMaker}
              onChange={handleChange}
            />
            Enable Meme Maker
          </label>
        </div>
        
        {enableMemeMaker && (
          <>
            <p>Choose the default background of the meme maker, and the image you want to use as overlay</p>
            <div className="image-upload-container">
              {[
                { type: 'overlay', file: overlayFile, preview: overlayPreview, setter: setOverlayFile, previewSetter: setOverlayPreview, ref: overlayInputRef },
                { type: 'defaultbg', file: defaultBgFile, preview: defaultBgPreview, setter: setDefaultBgFile, previewSetter: setDefaultBgPreview, ref: defaultBgInputRef }
              ].map(({ type, file, preview, setter, previewSetter, ref }) => (
                <div key={type} className="image-upload-square">
                  <label htmlFor={`${type}-upload`} className="file-upload-label">
                    {preview ? (
                      <img src={preview} alt={`${type} preview`} className="image-preview" />
                    ) : (
                      `Upload ${type === 'overlay' ? 'Overlay' : 'Default BG'}`
                    )}
                  </label>
                  <input
                    type="file"
                    id={`${type}-upload`}
                    onChange={(e) => handleFileChange(e, setter, previewSetter)}
                    ref={ref}
                    accept="image/*"
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>


          </form>
          </div>
      <div className="confirm-pay-container">
      <button 
  type="button" 
  onClick={handleVerifyAndPay} 
  disabled={!formIsValid}
  className={formIsValid ? '' : 'disabled'}
>
  Confirm & Pay 1 SOL
</button>
      <span className="warning">You will NOT be able to change your site address later.</span>
      {status && <div className="status-message">{status}</div>}
    </div>
    {showPopUp && isPopUpVisible && (
  <div className={`pop-up-message ${showPopUp ? 'show' : ''}`}>
    <button className="close-button" onClick={closePopUp}>&times;</button>
    <p>
      Don't forget to update all information tabs: basic, socials, technical, design and mememaker!
      You can always edit your website after for free. 
      You have 10min to register this site until name reservation expires.
      If you keep a field empty (e.g : Overlay for Meme Maker) the whole component will not be displayed on the page. 
    </p>
  </div>
)}
    </div>
    
  );
};

export default RegistrationPage;