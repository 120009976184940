import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import './MySites.css';

const MySites = () => {
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const { publicKey } = useWallet();

  useEffect(() => {
    const fetchSites = async () => {
      if (!publicKey) return;
      try {
        const response = await fetch(`https://api.site.fun/api/my-sites?publicKey=${publicKey.toBase58()}`);
        if (!response.ok) throw new Error('Failed to fetch sites');
        const data = await response.json();
        setSites(data.websites || []);
      } catch (err) {
        console.error(err);
        // Even if there's an error, we'll show the "No sites" message
        setSites([]);
      } finally {
        setLoading(false);
      }
    };
    fetchSites();
  }, [publicKey]);

  if (loading) return <div className="loading">Loading...</div>;

  return (
    <div className="my-sites-page">
      <h1>[my sites]</h1>
      {sites.length === 0 ? (
        <div className="no-sites">
          <p>You haven't created any sites yet</p>
        </div>
      ) : (
        <div className="sites-grid">
          {sites.map((site) => (
            <div key={site.subdomain} className="site-card">
              <img
                src={site.logo_url || '/placeholder-logo.png'}
                alt={`${site.subdomain} logo`}
                className="site-logo"
              />
              <h2 className="site-subdomain">{site.subdomain}.site.fun</h2>
              <p className="site-created-at">created on {new Date(site.created_at).toLocaleDateString()}</p>
              <a href={`https://${site.subdomain}.site.fun`} target="_blank" rel="noopener noreferrer" className="visit-site-button">
                [visit site]
              </a>
              <a href={`/edit/${site.subdomain}`} className="edit-button">
                [edit]
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MySites;