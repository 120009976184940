import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  LedgerWalletAdapter,
  CoinbaseWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import HomePage from './pages/HomePage';
import RegistrationPage from './pages/RegistrationPage';
import Header from './components/Header';
import { AppProvider } from './AppContext';
import './App.css';
import SuccessPage from './pages/SuccessPage';
import HowItWorksPopUp from './components/HowItWorksPopUp';
import '@solana/wallet-adapter-react-ui/styles.css';
import MySites from './components/MySites';
import EditPage from './components/EditPage';
const App = () => {
  const network = WalletAdapterNetwork.Mainnet;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const endpoint = React.useMemo(() => {
    if (network === WalletAdapterNetwork.Devnet) {
      return "https://api.devnet.solana.com";
    } else if (network === WalletAdapterNetwork.Mainnet) {
      return "https://mainnet.helius-rpc.com/?api-key=7b8b28b2-0cd4-46bb-949e-ff2f3420084c";
    } else {
      return clusterApiUrl(network);
    }
  }, [network]);

  const wallets = React.useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new CoinbaseWalletAdapter(),
    ],
    []
  );


// Modified code to fix scrolling issues
useEffect(() => {
  const handleTouchMove = (e) => {
    // Only prevent default for horizontal scrolling
    if (Math.abs(e.touches[0].clientX - e.touches[0].clientY) > 50) {
      e.preventDefault();
    }
  };

  // Add event listener with passive: true for better performance
  document.body.addEventListener('touchmove', handleTouchMove, { passive: true });

  // Cleanup
  return () => {
    document.body.removeEventListener('touchmove', handleTouchMove);
  };
}, []);


  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setIsPopupOpen(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <AppProvider>
            <Router>
              <div className="App">
                <Header setIsPopupOpen={setIsPopupOpen} />
                <Routes>
                  <Route path="/" element={<HomePage setIsPopupOpen={setIsPopupOpen} />} />
                  <Route path="/register" element={<RegistrationPage />} />
                  <Route path="/success" element={<SuccessPage />} />
                  <Route path="/my-sites" element={<MySites />} />
                  <Route path="/edit/:subdomain" element={<EditPage />} />
                </Routes>
                <HowItWorksPopUp isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
              </div>
            </Router>
          </AppProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;