import React from 'react';
import { useAppContext } from '../AppContext';
import './SuccessPage.css';

const SuccessPage = () => {
  const { reservedDomain } = useAppContext();

  if (!reservedDomain || !reservedDomain.subdomain) {
    return (
      <div className="success-page">
        <h2>No site information</h2>
        <p>Please try again.</p>
      </div>
    );
  }

  const siteUrl = `https://${reservedDomain.subdomain}.site.fun`;

  return (
    <div className="success-page">
      <h2>Success!</h2>
      <div className="wiggling-logo-success">
        <img src="/logo/logo.png" alt="Logo" className="logo-success" />
      </div>
      <p>Your site is now live at:</p>
      <a href={siteUrl} target="_blank" rel="noopener noreferrer" className="site-link">
        [{reservedDomain.subdomain}.site.fun]
      </a>
    </div>
  );
};

export default SuccessPage;