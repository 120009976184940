import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [reservedDomain, setReservedDomain] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    twitter_link: '',
    dexscreener_link: '',
    telegram_link: '',
    pump_fun_link: '',
    discord_link: '',
    scanner_link: '',
    contract_address: '',
    description: '',
    ticker: '',
    background_image_url: '',
    logo_url: '',
    template_id: '',
    color1: '#000000',
    color2: '#000000',
    color3: '#000000',
  });

  const updateFormData = (name, value) => {
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  return (
    <AppContext.Provider value={{ 
      reservedDomain, 
      setReservedDomain, 
      formData, 
      updateFormData,
      setFormData // Add this line
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppContext;