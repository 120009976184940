import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Polyfills
import 'crypto-browserify';
import 'stream-browserify';
import 'stream-http';
import 'https-browserify';
import 'os-browserify';
import 'url';
import 'buffer';
import 'process/browser';
import 'browserify-zlib';  // Add this line

window.Buffer = window.Buffer || require('buffer').Buffer;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);