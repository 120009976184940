import React from 'react';
import './HowItWorksPopUp.css';

const HowItWorksPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={e => e.stopPropagation()}>
        <h2>How it works</h2>
        <p>
          Site helps "devs" creating sites in seconds. Each site on site is a subdomain page configured from templates.
        </p>
        <ol>
          <li>Choose a name for your site</li>
          <li>Reserve it</li>
          <li>Fill in all basic, technical, socials and design info</li>
          <li>Connect your wallet</li>
          <li>After paying, your site is live instantly</li>
        </ol>
        <button className="launch-button" onClick={onClose}>
          [launch my site]
        </button>
      </div>
    </div>
  );
};

export default HowItWorksPopup;