import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import './HomePage.css';
import LatestSites from '../components/LatestSites';
import Offer from  '../components/Offer';


const HomePage = ({ setIsPopupOpen }) => {

  const forbiddenDomains = [
    'www',
    'api'
  ];

  
  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setIsPopupOpen(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, [setIsPopupOpen]);

  const [availability, setAvailability] = useState(null);
  const [status, setStatus] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const navigate = useNavigate();
  const { setReservedDomain } = useAppContext();
  const timeoutRef = useRef(null);

  const checkAvailability = useCallback(async (subdomain) => {
    if (!subdomain) {
      setAvailability(null);
      setStatus('');
      return;
    }
     // Check if the subdomain is in the list of forbidden domains
     if (forbiddenDomains.includes(subdomain)) {
      setAvailability(false);
      setStatus('This name is not allowed.');
      return;
    }
    try {
      setIsChecking(true);
      setStatus('Checking availability...');
      const response = await fetch(`https://api.site.fun/api/check-availability?subdomain=${subdomain}`);
      const data = await response.json();
      setAvailability(data.available);
      setStatus(data.available ? 'Available' : 'Not available');
    } catch (error) {
      console.error('Error:', error);
      setStatus('An error occurred. Please try again.');
      setAvailability(null);
    } finally {
      setIsChecking(false);
    }
  }, []);

  const sanitizeSubdomain = (input) => {
    // Convert to lowercase
    let sanitized = input.toLowerCase();
    // Remove accents
    sanitized = sanitized.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    // Remove spaces and special characters, allow only alphanumeric and hyphens
    sanitized = sanitized.replace(/[^a-z0-9-]/g, '');
    // Remove consecutive hyphens
    sanitized = sanitized.replace(/-+/g, '-');
    // Remove leading and trailing hyphens
    sanitized = sanitized.replace(/^-|-$/g, '');
    return sanitized;
  };

  const handleSubdomainChange = (event) => {
    const rawValue = event.target.value;
    const sanitizedValue = sanitizeSubdomain(rawValue);
    setSubdomain(sanitizedValue);
    setAvailability(null);
    setStatus('');
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      checkAvailability(sanitizedValue);
    }, 1000);
  };

  const handleDeploy = async () => {
    if (!availability) return;
    try {
      setStatus('Reserving name...');
      const reserveResponse = await fetch('https://api.site.fun/api/reserve-subdomain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subdomain }),
      });
      const reserveData = await reserveResponse.json();
      if (reserveData.success) {
        setReservedDomain({ subdomain, id: reserveData.reservation_id });
        setStatus('Name reserved successfully!');
        setTimeout(() => navigate('/register'), 1000);
      } else {
        setStatus('Failed to reserve name. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('An error occurred. Please try again.');
    }
  };

  return (
    <div className="home-page">
      <h1>[create your site]</h1>
      <div className="input-container">
        <div className="subdomain-input-wrapper">
          <input
            type="text"
            value={subdomain}
            onChange={handleSubdomainChange}
            disabled={isChecking}
            placeholder="yoursite"
            className="subdomain-input"
          />
          <span className="domain-suffix">.site.fun</span>
        </div>
        <button
          onClick={handleDeploy}
          disabled={!availability || isChecking}
          className={availability && !isChecking ? 'deploy-button' : 'deploy-button disabled'}
        >
          deploy
        </button>
      </div>
      <div className="status-container">
      {status && (
        <p className={`status-message ${availability ? 'available' : 'not-available'}`}>
          {status}
        </p>
      )}
    </div>
    <h1>[latest sites]</h1>
    <LatestSites />
  </div>
);
};

export default HomePage;