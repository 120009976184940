import React from 'react';
import { Link } from 'react-router-dom';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import './Header.css';

const CustomWalletButton = () => {
  const { connected, publicKey } = useWallet();
  
  const truncateAddress = (address) => {
    if (!address) return '';
    return `.${address.slice(-4)}`;
  };

  return (
    <WalletMultiButton className="wallet-adapter-button-trigger">
      {connected ? `[${truncateAddress(publicKey?.toBase58())}]` : '[connect wallet]'}
    </WalletMultiButton>
  );
};

const Header = ({ setIsPopupOpen }) => {
  const { connected } = useWallet();

  const handleHowItWorksClick = (e) => {
    e.preventDefault();
    setIsPopupOpen(true);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo-nav-wrapper">
          <a href="/"><img src="/logo/logo.png" alt="Logo" className="logo" /></a>
          <nav>
            <ul>
              {/*<li><Link to="/">[home]</Link></li>--!>*/}
              <li><a href="#" onClick={handleHowItWorksClick}>[how it works]</a></li>
              <li><a href="https://t.me/sitedotfun" target="_blank">[telegram]</a></li>
              <li><a href="https://twitter.com/sitedotfun" target="_blank">[twitter]</a></li>
            </ul>
          </nav>
        </div>
        <div className="wallet-button-wrapper">
          <CustomWalletButton />
          {connected && <Link to="/my-sites" className="my-sites-link">[my sites]</Link>}
        </div>
      </div>
    </header>
  );
};

export default Header;