import React, { useState, useEffect } from 'react';
import './LatestSites.css';

const LatestSites = () => {
  const [sites, setSites] = useState([]);
  const [lastCheck, setLastCheck] = useState(new Date().toISOString());

  useEffect(() => {
    fetchInitialSites();
    const interval = setInterval(checkNewSites, 5000); // Poll every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const fetchInitialSites = async () => {
    try {
      const response = await fetch('https://api.site.fun/api/latest-subdomains');
      const data = await response.json();
      setSites(data);
    } catch (error) {
      console.error('Error fetching initial sites:', error);
    }
  };

  const checkNewSites = async () => {
    try {
      const response = await fetch(`https://api.site.fun/api/check-new-subdomains?last_check=${lastCheck}`);
      const newSites = await response.json();
      if (newSites.length > 0) {
        setSites(prevSites => {
          const uniqueNewSites = newSites.filter(newSite =>
            !prevSites.some(existingSite => existingSite.subdomain === newSite.subdomain)
          );
          return [...uniqueNewSites, ...prevSites].slice(0, 20);
        });
        setLastCheck(new Date().toISOString());
      }
    } catch (error) {
      console.error('Error checking new sites:', error);
    }
  };

  return (
    <div className="latest-sites-grid">
      {sites.map((site, index) => (
        <div
          key={site.subdomain}
          className={`site-container ${index === 0 ? 'new-site' : ''}`}
        >
          <img src={site.logo_url || '/logo/logo.png'} alt={`${site.name} logo`} className="site-logo" />
          <h3>{site.name} ${site.ticker}</h3>
          <a href={`https://${site.subdomain}.site.fun`} target="_blank" rel="noopener noreferrer" className="site-link">
            [{site.subdomain}]
          </a>
          <p className="created-at">Created: {site.time_ago_formatted}</p>
        </div>
      ))}
    </div>
  );
};

export default LatestSites;