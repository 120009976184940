import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import bs58 from 'bs58';
import { useAppContext } from '../AppContext';
import './EditPage.css';

const EditPage = () => {
  const navigate = useNavigate();
  const { subdomain } = useParams();
  const { publicKey, signMessage } = useWallet();
  const { updateFormData } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [status, setStatus] = useState('');
  const [activeTab, setActiveTab] = useState('basic');
  const [enableMemeMaker, setEnableMemeMaker] = useState(false);
  const [memeImages, setMemeImages] = useState([
    { file: null, url: '', toRemove: false, initialUrl: '' },
    { file: null, url: '', toRemove: false, initialUrl: '' },
    { file: null, url: '', toRemove: false, initialUrl: '' }
  ]);
  const [formData, setFormData] = useState({
    name: '',
    ticker: '',
    description: '',
    twitter_link: '',
    telegram_link: '',
    contract_address: '',
    pump_fun: false,
    dexscreener: false,
    raydium: false,
    template_id: 'classic'
  });

  const [errors, setErrors] = useState({});
  const [backgroundImage, setBackgroundImage] = useState({ file: null, url: '', toRemove: false, initialUrl: '' });
  const [logoImage, setLogoImage] = useState({ file: null, url: '', toRemove: false, initialUrl: '' });
  const [overlayImage, setOverlayImage] = useState({ file: null, url: '', toRemove: false, initialUrl: '' });
  const [defaultBgImage, setDefaultBgImage] = useState({ file: null, url: '', toRemove: false, initialUrl: '' });

  useEffect(() => {
    const fetchSiteData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`https://api.site.fun/api/get-site-data?subdomain=${subdomain}`);
        if (!response.ok) throw new Error('Failed to fetch site data');
        const data = await response.json();
    
        setFormData({
          ...data,
          pump_fun: data.pump_fun || false,
          dexscreener: data.dexscreener || false,
          raydium: data.raydium || false,
          template_id: data.template_id || 'classic'
        });
        setBackgroundImage({
          file: null,
          url: data.background_image_url || '',
          toRemove: false,
          initialUrl: data.background_image_url || ''
        });
        setLogoImage({
          file: null,
          url: data.logo_url || '',
          toRemove: false,
          initialUrl: data.logo_url || ''
        });
        setMemeImages([
          { file: null, url: data.meme1 || '', toRemove: false, initialUrl: data.meme1 || '' },
          { file: null, url: data.meme2 || '', toRemove: false, initialUrl: data.meme2 || '' },
          { file: null, url: data.meme3 || '', toRemove: false, initialUrl: data.meme3 || '' }
        ]);
        setOverlayImage({
          file: null,
          url: data.overlay || '',
          toRemove: false,
          initialUrl: data.overlay || ''
        });
        setDefaultBgImage({
          file: null,
          url: data.defaultbgmememaker || '',
          toRemove: false,
          initialUrl: data.defaultbgmememaker || ''
        });
        setIsAuthorized(publicKey && publicKey.toBase58().toLowerCase() === data.wallet_address.toLowerCase());
        setEnableMemeMaker(!!(data.overlay || data.defaultbgmememaker));
      } catch (error) {
        console.error('Error fetching site data:', error);
        setStatus('Failed to load site data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSiteData();
  }, [subdomain, publicKey]);

  const handleMemeFileChange = (index, file) => {
    setMemeImages(prev => {
      const newMemeImages = [...prev];
      newMemeImages[index] = {
        ...newMemeImages[index],
        file,
        url: file ? URL.createObjectURL(file) : '',
        toRemove: false
      };
      return newMemeImages;
    });
  };

  const handleRemoveMeme = (index) => {
    setMemeImages(prev => {
      const newMemeImages = [...prev];
      newMemeImages[index] = {
        ...newMemeImages[index],
        file: null,
        url: '',
        toRemove: true
      };
      return newMemeImages;
    });
  };



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? checked : value;

    if (name === 'name') newValue = newValue.slice(0, 32);
    if (name === 'description') newValue = newValue.slice(0, 300);
    if (['twitter_link', 'telegram_link'].includes(name)) newValue = newValue.replace(/\s/g, '').slice(0, 100);
    if (name === 'contract_address' && !formData.contract_address) {
      newValue = newValue.replace(/[^a-zA-Z0-9]/g, '').slice(0, 100);
    }

    setFormData(prev => ({ ...prev, [name]: newValue }));
    validateField(name, newValue);
    if (name === 'enable_meme_maker') {
      setEnableMemeMaker(checked);
      if (!checked) {
        clearMemeMakerImages();
      }
    }
  };

  const clearMemeMakerImages = () => {
    setOverlayImage(prev => ({ ...prev, toRemove: true, file: null, url: '' }));
    setDefaultBgImage(prev => ({ ...prev, toRemove: true, file: null, url: '' }));
  };

  const handleFileChange = (e, imageType) => {
    const file = e.target.files[0];
    if (file) {
      const imageState = { 
        file, 
        url: URL.createObjectURL(file), 
        toRemove: false, 
        initialUrl: '' 
      };
      switch (imageType) {
        case 'background':
          setBackgroundImage({ ...imageState, initialUrl: backgroundImage.initialUrl });
          break;
        case 'logo':
          setLogoImage({ ...imageState, initialUrl: logoImage.initialUrl });
          break;
        case 'overlay':
          setOverlayImage({ ...imageState, initialUrl: overlayImage.initialUrl });
          break;
        case 'defaultbg':
          setDefaultBgImage({ ...imageState, initialUrl: defaultBgImage.initialUrl });
          break;
        default:
          console.error('Unknown image type:', imageType);
      }
    }
  };

  const handleRemoveImage = (imageType) => {
    switch (imageType) {
      case 'background':
        setBackgroundImage(prev => ({ ...prev, toRemove: true, file: null, url: '' }));
        break;
      case 'logo':
        setLogoImage(prev => ({ ...prev, toRemove: true, file: null, url: '' }));
        break;
      case 'overlay':
        setOverlayImage(prev => ({ ...prev, toRemove: true, file: null, url: '' }));
        break;
      case 'defaultbg':
        setDefaultBgImage(prev => ({ ...prev, toRemove: true, file: null, url: '' }));
        break;
      default:
        console.error('Unknown image type:', imageType);
    }
  };

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'name':
        if (!value.trim()) error = 'Name is required';
        break;
      case 'twitter_link':
        if (value && !value.includes('x.com') && !value.includes('twitter.com')) error = 'Twitter link is not valid';
        break;
      case 'telegram_link':
        if (value && !value.includes('t.me') && !value.includes('telegram.com')) error = 'Telegram link is not valid';
        break;
      case 'contract_address':
        if (!formData.contract_address && value && (value.length < 25 || value.length > 100)) {
          error = 'Contract address must be between 25 and 100 characters';
        }
        break;
      default:
        break;
    }
    setErrors(prev => ({ ...prev, [name]: error }));
  };

  const validateForm = () => {
    let isValid = true;
    Object.entries(formData).forEach(([key, value]) => {
      validateField(key, value);
      if (errors[key]) isValid = false;
    });
    return isValid;
  };


  const removeImageFromServer = async (fileType, currentUrl) => {
    try {
      const response = await fetch('https://api.site.fun/api/update-image', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          base64_file: '',
          file_type: fileType,
          subdomain: subdomain,
          current_url: currentUrl
        }),
      });
  
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  
      const result = await response.json();
      return result.url; // This will be an empty string
    } catch (error) {
      console.error('Error removing image:', error);
      throw error;
    }
  };


  const uploadImage = async (file, fileType, currentUrl, isNew) => {
    if (!file && !isNew) {
      return removeImageFromServer(fileType, currentUrl);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        const base64File = reader.result;
        try {
          const response = await fetch('https://api.site.fun/api/update-image', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              base64_file: base64File,
              file_type: fileType,
              subdomain: subdomain,
              current_url: isNew ? '' : currentUrl
            }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || 'Unknown error'}`);
          }

          const result = await response.json();
          resolve(result.url);
        } catch (error) {
          console.error('Error uploading image:', error);
          reject(error);
        }
      };
    });
  };

  


  const handleUpdate = useCallback(async () => {
    if (!isAuthorized) {
      setStatus('You are not authorized to edit this site.');
      return;
    }
  
    if (!validateForm()) {
      setStatus('Please correct the form errors');
      return;
    }
  
    if (!publicKey) {
      setStatus('Wallet not connected');
      return;
    }
  
    setStatus('Verifying wallet...');
  
    try {
      const message = `Verify wallet ownership for site.fun update: ${new Date().toISOString()}`;
      const encodedMessage = new TextEncoder().encode(message);
      const signedMessage = await signMessage(encodedMessage);
      const signature = bs58.encode(signedMessage);
  
      const verifyResponse = await fetch('https://api.site.fun/api/verify_signature', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          public_key: publicKey.toBase58(),
          message: bs58.encode(encodedMessage),
          signature: signature,
        }),
      });
      
      const verifyResult = await verifyResponse.json();
      if (!verifyResult.is_valid) {
        setStatus('Signature verification failed');
        return;
      }
  
      setStatus('Wallet verified. Updating site...');

      let backgroundUrl = backgroundImage.url;
      let logoUrl = logoImage.url;
      let overlayUrl = overlayImage.url;
      let defaultBgUrl = defaultBgImage.url;

      if (backgroundImage.toRemove && backgroundImage.initialUrl) {
        backgroundUrl = await removeImageFromServer('background', backgroundImage.initialUrl);
      } else if (backgroundImage.file) {
        backgroundUrl = await uploadImage(backgroundImage.file, 'background', backgroundImage.initialUrl);
      }

      if (logoImage.toRemove && logoImage.initialUrl) {
        logoUrl = await removeImageFromServer('logo', logoImage.initialUrl);
      } else if (logoImage.file) {
        logoUrl = await uploadImage(logoImage.file, 'logo', logoImage.initialUrl);
      }

      if (enableMemeMaker) {
        if (overlayImage.toRemove && overlayImage.initialUrl) {
          overlayUrl = await removeImageFromServer('overlay', overlayImage.initialUrl);
        } else if (overlayImage.file) {
          overlayUrl = await uploadImage(overlayImage.file, 'overlay', overlayImage.initialUrl);
        }
  
        if (defaultBgImage.toRemove && defaultBgImage.initialUrl) {
          defaultBgUrl = await removeImageFromServer('defaultbgmememaker', defaultBgImage.initialUrl);
        } else if (defaultBgImage.file) {
          defaultBgUrl = await uploadImage(defaultBgImage.file, 'defaultbgmememaker', defaultBgImage.initialUrl);
        }
      } else {
        // If meme maker is disabled, remove existing images
        if (overlayImage.initialUrl) {
          await removeImageFromServer('overlay', overlayImage.initialUrl);
        }
        if (defaultBgImage.initialUrl) {
          await removeImageFromServer('defaultbgmememaker', defaultBgImage.initialUrl);
        }
        overlayUrl = '';
        defaultBgUrl = '';
      }
  
      const memeUrls = await Promise.all(memeImages.map(async (meme, index) => {
        if (meme.toRemove && meme.initialUrl) {
          return await removeImageFromServer(`meme${index + 1}`, meme.initialUrl);
        } else if (meme.file) {
          return await uploadImage(meme.file, `meme${index + 1}`, meme.initialUrl);
        }
        return meme.url;
      }));

      const updateResponse = await fetch('https://api.site.fun/api/edit-subdomain', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          wallet_address: publicKey.toString(),
          subdomain: subdomain,
          ...formData,
          background_image_url: backgroundUrl,
          logo_url: logoUrl,
          meme1: memeUrls[0],
          meme2: memeUrls[1],
          meme3: memeUrls[2],
          overlay: overlayUrl,
          defaultbgmememaker: defaultBgUrl,
        }),
      });

      const updateResult = await updateResponse.json();
      
      if (updateResult.success) {
        setStatus('Site updated successfully');
        navigate('/my-sites');
      } else {
        setStatus(`Failed to update site: ${updateResult.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus(`Update Failed: ${error.message}`);
    }
  }, [isAuthorized, publicKey, signMessage, formData, navigate, subdomain, backgroundImage, logoImage, memeImages, overlayImage, defaultBgImage]);

  if (isLoading) return <div className="edit-page">Loading...</div>;

  if (!isAuthorized) {
    return (
      <div className="edit-page">
        <h2>Unauthorized</h2>
        <p>{status}</p>
        <button onClick={() => navigate('/my-sites')}>[Back to My Sites]</button>
      </div>
    );
  }

  return (
    <div className="edit-page">
      <div className="edit-container">
        <h2>Edit {subdomain}.site.fun</h2>
        
        <div className="tab-container">
          {['basic', 'social', 'technical', 'design', 'memes', 'mememaker'].map(tab => (
            <div
              key={tab}
              className={`tab ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              [{tab}]
            </div>
          ))}
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          <div className={`form-section ${activeTab === 'basic' ? 'active' : ''}`}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name (required, max 32 chars)"
              required
              maxLength={32}
              className={formData.name ? "non-editable" : ""}
            />
            {errors.name && <span className="error">{errors.name}</span>}

            <input
              type="text"
              name="ticker"
              value={formData.ticker}
              onChange={handleChange}
              placeholder="Ticker (max 10 chars)"
              maxLength={10}
              readOnly={!!formData.ticker}
              className={formData.ticker ? "non-editable" : ""}
            />

            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Description (max 300 chars)"
              maxLength={300}
            />
          </div>

          <div className={`form-section ${activeTab === 'social' ? 'active' : ''}`}>
          <p>Enter your socials </p>
            <input
              type="text"
              name="twitter_link"
              value={formData.twitter_link}
              onChange={handleChange}
              placeholder="Twitter Link"
            />
            {errors.twitter_link && <span className="error">{errors.twitter_link}</span>}

            <input
              type="text"
              name="telegram_link"
              value={formData.telegram_link}
              onChange={handleChange}
              placeholder="Telegram Link"
            />
            {errors.telegram_link && <span className="error">{errors.telegram_link}</span>}
          </div>

          <div className={`form-section ${activeTab === 'technical' ? 'active' : ''}`}>
          <p>Enter your CA and choose the platforms you're present on</p>
          <span className="warning">If you launched on Moonshot, you can tick Dexscreener</span>

            <input
              type="text"
              name="contract_address"
              value={formData.contract_address}
              onChange={handleChange}
              placeholder="Contract Address (25-100 chars)"
              readOnly={!!formData.contract_address}
              className={formData.contract_address ? "non-editable" : ""}
            />
            {errors.contract_address && <span className="error">{errors.contract_address}</span>}

            {['pump_fun', 'dexscreener', 'raydium'].map(name => (
              <label key={name}>
                <input
                  type="checkbox"
                  name={name}
                  checked={formData[name]}
                  onChange={handleChange}
                />
                {name.charAt(0).toUpperCase() + name.slice(1).replace('_', '.')}
              </label>
            ))}
          </div>

          <div className={`form-section ${activeTab === 'design' ? 'active' : ''}`}>
          <p>Choose your website's background, logo and template</p>
  <div className="image-upload-container">
    {['background', 'logo'].map(imageType => (
      <div key={imageType} className="image-upload-square">
        {(imageType === 'background' ? backgroundImage : logoImage).url && 
         !(imageType === 'background' ? backgroundImage : logoImage).toRemove ? (
          <div>
            <img 
              src={(imageType === 'background' ? backgroundImage : logoImage).url} 
              alt={`Current ${imageType}`} 
              className="image-preview" 
            />
            <button type="button" onClick={() => handleRemoveImage(imageType)} className="remove-image-btn">
              Remove
            </button>
          </div>
        ) : (
          <label htmlFor={`${imageType}-upload`} className="file-upload-label">
            Upload {imageType}
          </label>
        )}
        <input
          type="file"
          id={`${imageType}-upload`}
          onChange={(e) => handleFileChange(e, imageType)}
          accept="image/*"
        />
      </div>
    ))}
  </div>

  <div className="form-group">
    <label htmlFor="template_id">Template:</label>
    <select
      id="template_id"
      name="template_id"
      value={formData.template_id}
      onChange={handleChange}
    >
      <option value="4chan">4chan</option>
      <option value="apesinvasion">apesinvasion</option>
      <option value="basic">basic</option>
      <option value="basicdark">basicdark</option>
      <option value="coinhub">coinhub</option>
      <option value="dark">dark</option>
      <option value="futuristic">futuristic</option>
      <option value="japan">japan</option>
      <option value="degen">degen</option>
      <option value="blackandwhite">blackandwhite</option>
      <option value="reddit">reddit</option>
      <option value="retro">retro</option>
      <option value="simple">simple</option>

    </select>
    <span className="warning2">Preview available templates on <a href="https://preview.site.fun" target="_blank">preview.site.fun</a></span>
  </div>
</div>
<div className={`form-section ${activeTab === 'memes' ? 'active' : ''}`}>
  <p>Choose up to 3 memes to display on your website</p>
  <div className="meme-upload-container">
    {memeImages.map((meme, index) => (
      <div key={index} className="meme-upload-square">
        {meme.url && !meme.toRemove ? (
          <div>
            <img src={meme.url} alt={`Current Meme ${index + 1}`} className="meme-preview" />
            <button type="button" onClick={() => handleRemoveMeme(index)} className="remove-meme-btn">
              Remove
            </button>
          </div>
        ) : (
          <label htmlFor={`meme-upload-${index}`} className="file-upload-label">
            Upload Meme
          </label>
        )}
        <input
          type="file"
          id={`meme-upload-${index}`}
          onChange={(e) => handleMemeFileChange(index, e.target.files[0])}
          accept="image/*"
        />
      </div>
    ))}
  </div>
</div>
<div className={`form-section ${activeTab === 'mememaker' ? 'active' : ''}`}>
  <div className="form-group">
    <label>
      <input
        type="checkbox"
        name="enable_meme_maker"
        checked={enableMemeMaker}
        onChange={handleChange}
      />
      Enable Meme Maker
    </label>
  </div>
  {enableMemeMaker && (
    <>
      <p>Choose the default background of the meme maker, and the image you want to use as overlay</p>
      <div className="image-upload-container">
        {[
          { label: 'Overlay', state: overlayImage, setState: setOverlayImage, type: 'overlay' },
          { label: 'Default Background', state: defaultBgImage, setState: setDefaultBgImage, type: 'defaultbg' }
        ].map(({ label, state, setState, type }) => (
          <div key={label} className="image-upload-square">
            {state.url && !state.toRemove ? (
              <div>
                <img src={state.url} alt={`Current ${label}`} className="image-preview" />
                <button type="button" onClick={() => handleRemoveImage(type)} className="remove-image-btn">
                  Remove
                </button>
              </div>
            ) : (
              <label htmlFor={`${type}-upload`} className="file-upload-label">
                Upload {label}
              </label>
            )}
            <input
              type="file"
              id={`${type}-upload`}
              onChange={(e) => handleFileChange(e, type)}
              accept="image/*"
            />
          </div>
        ))}
      </div>
    </>
  )}
</div>
      </form>
    </div>
    <div className="update-container">
      <button type="button" onClick={handleUpdate}>[UPDATE]</button>
      {status && <div className="status-message">{status}</div>}
    </div>
  </div>
);
}

export default EditPage;